// @contextW: 68vw;
// @sidebarW: calc(~'100vw - @{contextW} - @{margin} * 3');

// @contextW: 68vw;
@atlasSidebarW: 27rem;

.table-tools {
    display: flex;
    align-items: center;

    .table-row-count {
        margin-left: @margin *0.5;
    }
}