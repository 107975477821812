.helptext {
    font-size: 0.9rem;
    font-style: italic;
    line-height: 125%;
    box-sizing: border-box;
    margin-bottom: @margin;

    p {
        margin-bottom: (@margin / 2);

        &:last-child {
            margin: 0;
        }
    }
}
