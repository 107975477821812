.chart-bar-bar {
    font-family: monospace;
    cursor: pointer;
    fill: lightblue;
}

.chart-bar-tick-label {
    fill: #666;
    font-size: 80%;
}

.chart-bar-tick, .chart-bar-border {
    stroke: #666;
    fill: none;
}

.chart-bar-bar.active {
    fill: darkblue;
}

.chart-bar-bar-label {
    display: none;
}

.chart-bar-bar-label-line {
    stroke: darkblue;
    stroke-width: 1px;
    stroke-dasharray: 1,3;
    stroke-line-cap: 'round';
}
/*
.chart-bar-bar:hover .chart-bar-bar-label {
    display: initial;
    z-index: 1;
}*/

.chart-bar-selection-border {
    stroke: darkblue;
    stroke-width: 1px;
    stroke-dasharray: 1,4;
    stroke-line-cap: 'round';
}

.chart-bar-selection-adjust {
    stroke: darkblue;
    stroke-width: 1px;
    cursor: ew-resize;
    fill: white;
    display: block;
}

.chart-bar-selection-fill {
    fill: lightblue;
    fill-opacity: .25;
}

.chart-bar-bar-line {
    stroke: darkblue;   
    stroke-width: 1px;
    fill: transparent;
}

.chart-bar-cursor {
    stroke: darkred;
    stroke-width: 1px;
}

.chart-bar-data-point {
    fill: darkred;
}

.chart-bar-grid { 
    stroke-width: 1px;
    stroke: rgb(150,150,150);
    stroke-dasharray: 1,3;
    stroke-line-cap: round;
}