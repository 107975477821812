.map-wrapper {

    .loading-layer-wrapper {
        .absolute(auto, auto, 0, 0);
        z-index: 10;
        min-width: 200px;
        padding-right: @small-margin;
        padding-top: @small-margin;
        border: 1px solid @borderLightColor;
        border-bottom-color: @bodyBgColor;
        border-left-color: @bodyBgColor;
        background-color: @mapBgColor;
        font-size: 0.8rem;

        &.hidden {
            display: none;
        }

        .loading-layer {
            margin-top: @small-margin;
        }
    }

}