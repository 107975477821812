@edito-title-height: 12rem;

.dashboard {
    .edito {
        .illu__img {
            width: 100%;
            padding-bottom: 66%;
            background-image: url('../../assets/imgs/dashboard-img.jpg');
            background-size: cover;
            margin-top: calc(~'-@{edito-title-height} + @{margin}');
        }

        //temp Hack ON -- Keep it for later use
        // .illu__caption:after {
        //     content: '';
        //     .textExtraSmall();
        //     width: 100%;
        //     margin-top: (@margin / 3);
        // }

        // .edito__title:before {
        //     content: 'Data to project';
        //     width: 100%;
        // }

        //temp Hack OFF
    }

    .app-name {
        //font-family: 'nunito';
        font-family: 'helvetica-neue';
    }
}
