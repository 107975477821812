


    html[lang='fr'] .embed-app .brand-logo .brand-name {
        width: 240px;
    }
    
    html[lang='nl'] .embed-app .brand-logo .brand-name {
        width: 208px;
    }
    

.embed-app {

    .map-wrapper {
        .control-box {
            border-top-left-radius: 1em;
        }
        .credit{
            position: relative !important;
            a {
                box-shadow: initial;
            }
        }
    }
}