.chart-wrapper {
    position: relative;

    h2 {
        margin: 0 0 @margin 0;
    }
}

.chart-viz {
    margin-bottom: @margin;

    svg {
        width: 66%;
        margin: auto;
        padding: 2 * @margin;
        padding-bottom: @margin;
    }

    text-align: center;
}

.chart-legend {
    display: flex;
    flex-wrap: wrap;

    .chart-legend-item {
        display: flex;
        padding: (@margin / 2) @margin;
        width: 50%;

        .chart-item-color {
            flex-shrink: 0;
            width: 30px;
            height: 30px;
            margin-right: @small-margin;
            border-radius: 30px;
            border: 8px solid @white;
        }

        .chart-item-content {
            text-align: left;

            .chart-item-value {
                font-size: 60%;
                // font-weight: @fwLight;
                margin-top: @small-margin;
                letter-spacing: 1px;
            }
        }
    }
}