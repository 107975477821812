// TOOLS :  WIDGET on MAP FULL SCREEN
.tool-widget {
    .absolute (@margin, @margin, initial, initial);
    width: @panel-rightW;
    height: auto !important;
    padding: @small-margin;
    background: #fff;
    border: 1px solid @borderLightColor;

    >div {
        margin-bottom: @margin;

        &:last-child {
            margin: 0px;
        }
    }

    H2,
    H3 {
        margin-bottom: @margin;
    }

    z-index: 2;
}

// TOOLS GENERAL

.tool .btn.label,
.tool .btn.label-and-icon {
    margin-bottom: @margin/2;
}

// TOOLS :  PRINT

.tool.print {
    .print-block {
        margin-top: @margin;

        .print-format {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: @margin;

            >button {
                .transition;
                .shadow;
                display: flex;
                background: @white;
                border: 1px solid @borderLightColor;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 0.8rem;
                font-weight: 700;
                padding: (@margin / 2);
                margin-right: @margin;

                &:hover {
                    cursor: default;
                    color: @activeColor;
                    border: 2px solid @activeColor;
                    -webkit-box-shadow: 0px 0px 0px 0px rgba(50, 50, 50, 0.5);
                    -moz-box-shadow: 0px 0px 0px 0px rgba(50, 50, 50, 0.5);
                    box-shadow: 0px 0px 0px 0px rgba(50, 50, 50, 0.5);
                }
            }
        }

        .portrait {
            width: 42px;
            height: 60px;
        }

        .landscape {
            width: 60px;
            height: 42px;
        }
    }

    .custom-title {
        input {
            width: 100%;
            box-sizing: border-box;
        }

        .btn {
            margin-top: @margin;
        }
    }
}

// TOOLS : LOCATION - TRACKER

.main.map-and-tracker {
    right: @margin;
    left: @margin;

    @media @sdi-small {
        right: initial;
        left: initial;
    }

    .tool.geocoder {
        display: none;
    }
}

// TOOLS : LOCATION
.tool.location {
    .point-location {
        .lat-lon-inputs {
            margin-top: @margin;
            margin-bottom: (@margin/2);
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            label {
                width: calc(~'50% - @{margin} / 2 - @{small-margin} / 2 - 25px');
                display: flex;
                flex-direction: column;
            }

            input {
                margin: 0;
                margin-top: (@margin/4);
            }
        }

        .cursor-location {
            margin-top: @margin;

            .lat-lon-label,
            .lat-lon-value {
                display: flex;
                justify-content: space-between;
                margin-bottom: @small-margin;

                >div {
                    width: calc(~'50% - @{margin}');
                }
            }
        }
    }
}

// TOOLS : MEASURE
.tool.measure {
    .tool-body {
        display: flex;
        flex-direction: column;
    }
}

.main.map-and-measure {
    right: @margin  !important;
    padding: 0;
    // left: @margin !important;

    .tool-body {
        display: flex;
        flex-direction: column;
    }

    .geocoder-wrapper {
        display: none;
    }
}

.tool.measure {
    .btn.btn-start {
        margin-top: @margin;
    }
}

// TOOLS : SHARE
.tool.share,
.tool.embed {
    .helptext {
        margin-bottom: @margin;
    }

    .text-copy {
        margin: @small-margin 0 @margin 0;
        border: 1px solid @borderLightColor;
        padding: @small-margin;
        max-height: 4em;
        overflow-y: auto;
    }

    .text {
        font-size: @gui-font-size * 0.8;
        font-family: monospace;
        color: @inputPlaceholderColor;
    }

    .copy {
        .interactive;
        float: right;
        color: @activeColor;
        font-size: 0.9em;
        cursor: default;
        padding: 0 0 @small-margin @small-margin;

        .copied {
            color: transparent;
            position: absolute;
            display: block;
            margin-top: -40px;
            transition-duration: 3s;
            transition-delay: 0s;
            transition-timing-function: cubic-bezier(0, 2, 0.75, 1);
        }

        &:active .copied {
            color: @activeColor;
        }
    }
}

// TOOLS : SPATIAL FILTER

.tool.spatial-filter {

    .filter-selection__wrapper {
        margin-top: @margin;
        display: flex;
        flex-direction: column;
        align-items: baseline;

        >.btn {
            margin-bottom: @margin;
        }
    }

    .filter-action__wrapper {
        margin-top: @margin;

        >.btn {
            margin-right: @margin;
        }
    }

    .legend-group {
        margin-top: @margin;
    }
}