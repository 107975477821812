@fontFamilyName2: 'helvetica-neue';
@fontFamilyDir2: '../assets/fonts/helvetica-neue';
@fontFamilyPathBaseName2: 'helvetica_neue';

// EXTRA-BOLD -800

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}_condensed_77_bold.woff')
        format('woff');
    font-weight: 800;
    font-style: normal;
}

// BOLD - 700

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}_75_bold.woff')
        format('woff');
    font-weight: 700;
    font-style: italic;
}

// SEMI-BOLD - 600

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}_75_bold.woff')
        format('woff');
    font-weight: 600;
    font-style: normal;
}

// REGULAR - 400 - normal

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}.woff')
        format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}_italic.woff')
        format('woff');
    font-weight: 400;
    font-style: italic;
}
