// LEGEND : DATA VIEW

.layer-item {
    .word-wrap;
    // display: flex;
    // flex-wrap: wrap;
    // align-items: baseline;
    // justify-content: flex-start;
    margin: @small-margin 0;

    .error {
        text-decoration: line-through;
        color: @inactiveColor;
    }

    .layer-infos {
        flex-shrink: 0;
        width: 100%;
    }

    .layer-title__wrapper {
        display: flex;
        flex-direction: row-reverse;
        align-items: baseline;
        justify-content: flex-end;

        .layer-title.selected {
            color: @activeColor;
            text-decoration: underline;
            font-weight: @fwSemiBold;
        }
    }

    .layer-actions {
        // .legendItemPosition;
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8em;
        margin-top: @small-margin;
        margin-right: (@margin/2);

        >span {
            cursor: default;
            .interactive;
        }

        >*:hover {
            color: @activeColor;
        }

        &.bookmark {
            width: initial;
        }

        // disable download style

        details.disabled .btn {
            color: @disabledColor
        }

        details .btn {
            color: @textColor;
            border: 0px;
            padding: 0;
            width: 1.4em;

            .icon {
                color: inherit;
            }

            &:hover {
                color: @activeColor  !important;
            }
        }

        details[open] .btn {
            color: @activeColor
        }

        details .kv {
            margin-bottom: @margin*0.5;

            &:last-child {
                margin-bottom: 0px;
            }

            .key {
                font-weight: @fwSemiBold;
            }
        }
    }

    button:hover {
        color: @activeColor;
    }

    .visible:before {
        .far();
        content: @fa-var-eye;
    }

    .table:before {
        .fas();
        content: @fa-var-table;
    }

    .bookmark-edit:before {
        .fas();
        content: @fa-var-pencil-alt;
    }

    .bookmark-pos:before {
        .far();
        content: @fa-var-dot-circle;
    }

    .bookmark-remove:before {
        .fas();
        content: @fa-var-trash;
    }

    .not-visible {
        color: @inactiveColor;
        .interactive;

        &:before {
            .far();
            content: @fa-var-eye-slash;
        }
    }

    .visible:before,
    .not-visible:before,
    .bookmark-pos:before,
    .bookmark-remove:before,
    .table:before,
    .download:before {
        padding-right: @small-margin * 2;
    }
}


.layer-item.bookmark {
    display: flex;
    align-items: center;

    .btn-zoomOnFeature {
        margin: 0px;
        margin-left: @margin;
    }
}

.bookmark-modal {
    max-width: 300px;

    .input__wrapper--text {

        margin-bottom: @margin;
    }

    .input-color-wrapper {
        display: flex;
        align-items: center;

        .input-alpha-label {
            display: flex;
            margin-left: @margin;

            .label {
                flex-shrink: 0;
                margin-right: @margin;
            }
        }
    }
}


// .datas-download-wrapper {
//     margin-top: @margin;

//     .dl-item {
//         .link;
//         margin-left: @small-margin;
//     }
// }