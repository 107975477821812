@btnFontSize: 0.9rem;
@innerPadding: (@btnFontSize / 4);
// @innerPadding: 16px;
@borderRadius: 3px;

// RESET BUTTON BASE STYLE

button {
    .focusVisible();
    border: 0px;
    background-color: transparent;
    font: 400 @btnFontSize @gui-font;
    padding: 0px;
}

// STRUCTURE

.btn {
    height: calc(~'2em + @{innerPadding}');
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0 @innerPadding * 3;
    box-sizing: border-box;
    flex-shrink: 0;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;
    width: fit-content;

    &.label-and-icon .icon {
        padding-right: 1em;
        font-size: 1rem;
    }

    &.icon-only {
        width: calc(~'2em + @{innerPadding}');
        padding: 0;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: inherit;

        .icon {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.tag {
        border-radius: 2em;
    }
}

// STYLE GENERAL

.btn::first-letter,
.btn .btn-label::first-letter,
.btn a::first-letter {
    text-transform: uppercase;
}

.label-and-icon,
.icon-only {
    .icon {
        // .awsomefont;
        .fas();
        color: @blue;
    }
}

.btn {
    .transition;
    cursor: default;
    // letter-spacing: 0.1ch;
    font-weight: @fwRegular;
    font-size: @btnFontSize;
    border-radius: @borderRadius;

    * {
        .transition;
    }

    a {
        color: inherit !important;
        text-decoration: none !important;
        box-shadow: initial !important;
    }
}

@btnInactive: @greyLight;

// STYLE LEVEL 1 - Big Buttons

@btn1Color: #fff;
@btn1InnerColor: @blue;
@btn1BorderColor: @btn1InnerColor;

@btn1ColorHover: @textColor;
@btn1InnerColorHover: @btn1Color;
@btn1BorderColorHover: @btn1InnerColor;

.btn-1 {
    background-color: @btn1InnerColor;
    color: @btn1Color;
    border: 2px solid @btn1BorderColor;

    &:hover,
    &:focus-visible {
        // .shadow();
        color: @btn1ColorHover;
        background: @btn1InnerColorHover;
        border: 2px solid @btn1BorderColorHover;
        outline: none;

        .icon {
            color: @blue;
        }
    }

    &.inactive,
    &.disabled {
        color: @btn1Color;
        background-color: @greyLight;
        border-color: @greyLight;

        .icon {
            color: @btn1Color;
        }
    }

    &:active {
        .no-shadow();
    }

    .icon {
        color: @btn1Color;
    }
}

// STYLE LEVEL 2 - Average buttons
@btn2Color: @textColor;
@btn2InnerColor: @bodyBgColor;
@btn2BorderColor: @blue;

@btn2ColorHover: @bodyBgColor;
@btn2InnerColorHover: @blue;
@btn2BorderColorHover: @blue;

.btn-2 {
    background-color: @btn2InnerColor;
    border: 2px solid @btn2BorderColor;
    color: @btn2Color;

    &:hover,
    &:focus-visible {
        color: @btn2ColorHover;
        background-color: @btn2InnerColorHover;
        border-color: @btn2BorderColorHover;
        outline: none;

        .icon {
            color: @btn2ColorHover;
        }
    }

    &.inactive,
    &.disabled {
        color: @btnInactive;
        background-color: transparent;
        border-color: @btnInactive;

        .icon {
            color: @btnInactive;
        }
    }
}

// STYLE LEVEL 3 - DENSE INTERFACE

@btn3Color: @textColor;
@btn3InnerColor: transparent;
@btn3BorderColor: @btn3InnerColor;

@btn3ColorHover: @textColor;
@btn3InnerColorHover: @btn3InnerColor;
@btn3BorderColorHover: @activeColor;

.btn-3 {
    background-color: @btn3InnerColor;
    border: 2px solid @btn3BorderColor;
    color: @btn3Color;

    &:hover,
    &:focus-visible {
        border: 2px solid @btn3BorderColorHover;
        color: @btn3ColorHover;
        outline: none;
    }

    &.inactive,
    &.disabled {
        color: @btnInactive;
        background-color: transparent;
        border-color: transparent;

        .icon {
            color: @btnInactive;
        }
    }

    // &.icon-only {
    //     // border: 0px;
    //     width: 1.25rem;
    //     height: 1.25rem;

    //     &:hover {
    //         color: @btn3ColorHover;
    //         // border: 0px;
    //     }
    // }
}

// TRANSLATE/D BUTTON
.btn-translate {
    font-size: 0.5rem !important;
    color: @greyLight;
    margin: 0 !important; //reset
    margin-right: -1em !important;
    margin-top: -1em !important;

    &.not-translated {
        color: red;
    }
}

// REMOVE - CONFIRMATION WIDGET

.btn.btn-remove {
    &:hover {
        border: 2px solid red;
    }

    &:active {
        .no-shadow();
    }
}

.remove-confirm {
    font-size: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: @white;
    padding: (@margin / 2);
    background: rgba(255, 255, 255, 0.2);

    .remove-confirm-box {
        -webkit-box-shadow: 0px 0px 30px 0px rgba(100, 100, 100, 0.5);
        -moz-box-shadow: 0px 0px 30px 0px rgba(100, 100, 100, 0.5);
        box-shadow: 0px 0px 30px 0px rgba(100, 100, 100, 0.5);
        background: @white;
        width: 33vw;
        height: 33vh;
        min-width: 350px;
        min-height: 250px;
        padding: @margin * 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .remove-confirm-btns {
            display: flex;
            justify-content: space-around;
        }
    }
}

/////// CHECKBOXES --> TO BE FIXED

.checkbox {
    cursor: default;

    &:before {
        // .awsomefont;
        .fas();
        margin-right: @small-margin;
    }
}

.checkbox {
    &::before {
        content: @fa-var-square;
    }

    &:hover:before,
    &.active:before {
        content: @fa-var-check-square;
    }
}

//// CUSTOM ICONS AND BUTTONS
// should be cleaned up when a specific icon-font exist

.zoom-rbc {
    .icon-rbc {
        background-image: url('../assets/pictos/brussels.svg');
        width: 100%;
        height: 100%;
    }

    &:hover,
    &.active {
        .icon-rbc {
            background-image: url('../assets/pictos/brussels-w.svg');
        }
    }
}