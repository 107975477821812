.sidebar__wrapper {
    .word-wrap;
    padding: 0 @margin;
    overflow-y: auto;

    h2,
    h3 {
        width: 100%;
        margin: @margin*0.5 0;
    }

    .sidebar-header {
        // margin-top: -2px;
        // border-bottom: 1px solid #000;
        margin-bottom: @margin*0.5;
    }
}