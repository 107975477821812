@bigSize: 1.2rem;

.view {
    .main.query {
        margin: 0 @margin;
        padding: @margin;
        border: @borderLight;
        overflow: auto;

        h1 {
            margin-bottom: @margin * 2;
        }

        h2 {
            margin-bottom: @margin;
        }

        h3 {
            margin-bottom: (@margin / 2);
        }

        .helptext {
            margin-bottom: @margin;
        }

        .query-actions {
            .absolute(@margin, @margin, initial, initial);

            >div {
                margin-left: @margin;
            }
        }

        .map-card {
            &__title {
                font-weight: @fwExtraBold;
                font-size: @bigSize;
                margin-bottom: (@margin / 2);
            }

            &__updated {
                font-size: 0.8rem;
                margin-bottom: @margin;
                font-style: italic;
            }

            &__description {
                margin-bottom: @margin;
            }

            &__layer {
                margin-bottom: (@margin / 2);

                &:last-child {
                    margin: 0;
                }
            }

            &.map-card--select {
                background-color: @bgLightColor;
                padding: @margin;
                margin-bottom: @margin;

                &:hover {
                    background-color: @bgLightColor - 5%;
                }

                .map-card--description {
                    margin-bottom: @margin;
                }

                .btn {
                    margin-right: @margin;
                    margin-bottom: @margin;
                }

                .map-card__layer {
                    cursor: default;

                    &:hover {
                        color: @activeColor;
                    }

                    &:before {
                        .fa();
                        display: inline-block;
                        width: 1.5em;
                        height: 1em;
                        margin-right: 0.5ch;
                    }

                    &.selected:before {
                        content: @fa-var-check-square;
                        color: @activeColor;
                    }

                    &.unselected:before {
                        .far();
                        content: @fa-var-square;
                    }
                }
            }
        }

        .selector-wrapper {
            display: flex;
            margin-bottom: @margin * 3;

            .result__map-illu {
                margin-right: @margin;
            }
        }

        .map-card--result {
            margin-bottom: @margin;

            .meta-wrapper {
                .wrapper_image-desc {
                    display: flex;

                    .map-card--title {}
                }
            }
        }

        .query-group.named {
            .query-group-title {
                margin: @margin 0;
                font-weight: @fwExtraBold;
            }

            .query-group-items {
                padding-left: @margin;
                margin-left: @margin;
                border-left: @borderDark;
            }
        }
    }
}

.view .query {
    .results-wrapper {
        .result-table {
            margin: @margin * 2 0;
        }

        .export-link__wrapper {
            .icon {
                .fa();
                margin-right: (@margin/2);
            }
        }

        .layer-title {
            font-size: 1.3rem;
            font-weight: 900;
            margin-bottom: @margin;
            margin-top: (@margin / 2);
        }
    }
}

.view .query {
    table {
        width: 100%;
        border-collapse: collapse;
        font-size: 0.85rem;
        line-height: 115%;
    }

    /* Zebra striping */
    tr:nth-of-type(odd) {
        background: #f8f8f8;
    }

    th {
        background: #333;
        color: white;
        font-weight: bold;
        margin-top: @margin * 2;
    }

    td,
    th {
        padding: 6px;
        border: 1px solid #ccc;
        text-align: left;
    }

    thead {
        tr:first-child {
            background-color: transparent;

            td:first-child {
                padding: 0;
                border: 0px;
                display: flex;

                .btn {
                    margin-right: @margin;
                }
            }
        }
    }
}

@media print {
    @page {
        size: landscape;
    }

    html {
        font-size: 9pt; // overide rem sizes
    }

    // imported on
    table {
        page-break-inside: auto;
    }

    tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }

    thead {
        display: table-header-group;
    }

    tfoot {
        display: table-footer-group;
    }

    // imported off

    .view {

        .header,
        .main.query,
        .footer {
            position: initial;
        }
    }

    .view .header {
        margin-bottom: @margin;

        >div {
            display: none;

            &.brand-logo {
                display: block;
            }
        }
    }

    .view .main.query {
        position: initial;
        overflow: hidden;
        padding: 0px;
        border: 0px;
        margin: 0px;
        margin-top: @margin * 3;
        margin-bottom: @margin * 2;

        H1 {
            font-size: 2rem;
            margin-bottom: @margin;
        }

        .selector-wrapper {
            page-break-after: always;
        }

        .selected-layer--print {
            font-size: 1.2rem;
            margin-bottom: (@margin / 2);
        }
    }

    .view table {
        table-layout: fixed;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }
}