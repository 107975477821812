// .tag__list {
//     .tag {
//         background-color: @activeColor;
//         color: #fff;
//         border: 1px solid @activeColor;
//     }

//     button {
//         color: #fff;

//         &:hover {
//             border-color: #fff;
//         }

//         .icon {
//             color: #fff;
//         }
//     }

// }

.tag__list {
    .tag {
        // background-color: @activeColor;
        color: @light-blue;
        border: 2px solid @light-blue;

        &.active,
        &.selected,
        &:hover {
            color: @bodyBgColor;
            background-color: @light-blue;

            button {
                color: @bodyBgColor;

                .icon {
                    color: @bodyBgColor;
                }

                &:hover {
                    background-color: @bodyBgColor;

                    .icon {
                        color: @light-blue;
                    }
                }
            }
        }
    }

    button {
        color: #fff;

        &:hover {
            background-color: @light-blue ;
            border-color: #fff;

            .icon {
                color: #fff;
            }
        }

        .icon {
            color: @light-blue;
        }

    }

}