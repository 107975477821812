.main.print {
    .absolute(@headerH, @margin, @margin, @margin);
    border: 1px solid @borderLightColor;
    padding: @margin;
    overflow: hidden;
}

.print-progress {
    .absolute(0, 0, 0, 0);
    z-index: 10;
    background: @bodyBgColor;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;

    .print-progress-item {
        margin-bottom: @margin;
        display: flex;

        &.todo:before {
            .fa();
            content: @fa-var-arrow-right;
            margin-right: (@margin / 2);
        }

        &.doing:before {
            .fa();
            .fa-spin();
            content: @fa-var-circle-notch;
            margin-right: (@margin / 2);
            color: @activeColor;
        }

        &.done:before {
            .fa();
            content: @fa-var-check-square;
            margin-right: (@margin / 2);
            color: @activeColor;
        }
    }
}
