@fontFamilyName1: 'source_sans';
@fontFamilyDir1: '../assets/fonts/sourcesans-pro';
@fontFamilyPathBaseName1: 'sourcesanspro';

// BLACK - 900

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-black.woff')
        format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-black-italic.woff')
        format('woff');
    font-weight: 900;
    font-style: italic;
}

// BOLD - 700

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-bold.woff')
        format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-bold-italic.woff')
        format('woff');
    font-weight: 700;
    font-style: italic;
}

// SEMI-BOLD - 600

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-semibold.woff')
        format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-semibold-italic.woff')
        format('woff');
    font-weight: 600;
    font-style: italic;
}

// REGULAR - 400

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-regular.woff')
        format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-italic.woff')
        format('woff');
    font-weight: 400;
    font-style: italic;
}

// LIGHT - 300

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-light.woff')
        format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-light-italic.woff')
        format('woff');
    font-weight: 300;
    font-style: italic;
}

// EXTRALIGHT - 200

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-extralight.woff')
        format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-extralight-italic.woff')
        format('woff');
    font-weight: 200;
    font-style: italic;
}
