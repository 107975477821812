.map-version {
    padding-bottom: @margin*0.5;
    border-bottom: @borderLight;
    margin-bottom: @margin*0.5;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .version-value {
        font-weight: @fwSemiBold;
        margin-right: 0.3rem;
    }

    .version-date {
        font-size: 0.75rem;
    }
}

.map-status {
    font-size: 0.7rem;
    border: @borderDark;
    border-color: @activeColor;
    background-color: @bodyBgColor;
    padding: 0.3em 0.5em;
    border-radius: 2em;
    width: fit-content;
    z-index: 2;
    margin-right: 0.3rem;

    .icon {
        margin-right: 1ch;
    }

    &.draft .icon {
        color: @greyDark;
    }

    &.published .icon {
        color: @blue;
    }

    &.public .icon {
        color: @green;
    }
}


.map-infos {
    padding-bottom: 0px !important;

    .map-date {
        display: flex;
        font-size: @gui-font-size * 0.8;
        margin-bottom: 0.5em;
    }

    .map-illustration {
        position: relative;
        width: 33%;
        margin-right: @margin;
        float: left;

        &.expanded {
            width: 100%;
            margin-right: 0px;
        }

        .expand-buttons__wrapper {
            display: none;
        }

        &:hover .expand-buttons__wrapper {
            display: block;
        }

        .expand-buttons__wrapper {
            position: absolute;
            top: @margin*0.5;
            left: @margin*0.5;
        }

        img {
            width: 100%;
            height: auto;
        }

    }


    .map-description {
        // margin: @margin*0.5 0;
        margin-bottom: @margin*0.5 ;
        line-height: 120%;

        .btn {
            margin-left: auto;
        }
    }

    .map-info-layers {
        display: none; // TEMPORARY

        .map-info-layer {
            margin-top: @margin;

            .map-info-layer-link {
                .link;
                width: 100%;

                &:before {
                    .far();
                    .legendItemPosition;
                    font-size: 80%;
                    content: @fa-var-file;
                }
            }
        }
    }
}

.map-attached-files,
.related-maps {
    padding-bottom: @margin*0.5;

    .link {
        .link;
        .word-wrap;
        display: flex;
        align-items: center;
        margin-bottom: @small-margin;

        &:before {
            .fa();
            .legendItemPosition;
            justify-content: center;
            content: @fa-var-play;
        }
    }
}

.map-attached-files .link:before {
    content: @fa-var-link;
}

.related-maps .link:before {
    content: @fa-var-play;
}

.title-reset-wrapper {
    display: flex;
    align-items: center;
}

.sidebar-reset-btn {
    margin-left: .5rem;
}

.btn-reset .icon:before {
    content: @fa-var-sync;
}