@page {
    size: landscape;
    margin: 7mm 7mm 7mm 7mm;
}

@media print {

    .header .app-title,
    .header .header-toolbar,
    .csac-credits,
    .footer__content .navigation,
    .app-name__wrapper:after,
    .switcher,
    .control-box,
    .geocoder-wrapper,
    .wms-legend-wrapper,
    .related-maps {
        display: none !important;
    }

    * {
        overflow: visible !important;
        print-color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
    }

    .footer__content {
        border: 0px;
    }
}