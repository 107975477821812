// text color is defined in ./colors.less
// default textcolor is @textColor.

//fonts
@gui-font: 'source_sans';
@gui-font-size: 1rem;

/******** FONT SIZES ********/

.textExtraLarge(@color: @textColor) {
    font-weight: @fwBlack; //black
    font-size: @gui-font-size * 2;
    color: @color;
}

.textBig(@color: @textColor) {
    font-weight: 900; //black
    font-size: @gui-font-size * 1.5;
    color: @color;
}

.textRegular(@color: @textColor) {
    font-weight: 500; // semi-bold
    font-size: @gui-font-size * 1.3;
    color: @color;
}

.textSmall(@color: @textColor) {
    font-weight: 400; // regular
    font-size: @gui-font-size;
    color: @color;
}

.textExtraSmall(@color: @textColor) {
    font-weight: @fwRegular; // regular
    font-size: @gui-font-size * 0.8;
    color: @color;
}

.textActivable() {
    &:hover,
    &--selected,
    &--active {
        color: @activeColor;
    }
}

/******** INTERACTIVE TEXT ********/

// decoration

.checkIcon(@color: @activeColor) {
    // .awsomefont();
    .fa();
    content: @fa-var-check;
    display: block;
    // position: absolute;
    // top: 0.2em;
    // top: 0;
    // right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @color;
    width: 1em;
    // height: 1em;
    height: 100%;
}

// Base

.activeTextBase() {
    position: relative;
    color: @textColor;
    border-bottom: 1px solid transparent;
    cursor: default;
    line-height: 110%;
    padding: 0.2em 0;
    display: flex;
    justify-content: space-between;
}

// Hover

.activeTextHover(@Color: @activeColor) {
    color: @Color;
    border-color: @Color;

    &::after {
        .checkIcon(@Color);
    }
}

// Pressed - OnClick

.activeTextPressed(@Color: @activeColorPressed) {
    color: @Color;
    border-color: @Color;

    &::after {
        .checkIcon(@Color);
    }
}

// current - selected

.activeTextCurrent(@Color: @activeColor) {
    color: @Color;

    // border-color: transparent;
    &::after {
        .checkIcon(@Color);
    }
}

// enabled

.activeTextEnabled(@Color: @activeColor) {
    color: @Color;

    // border-color: transparent;
    &::after {
        .checkIcon(@Color);
    }
}

// general mixin

.activeText() {
    .activeTextBase();

    &.enabled {
        .activeTextEnabled();
    }

    &.selected {
        .activeTextCurrent();
    }

    &:hover {
        .activeTextHover();
    }

    &:focus-visible,
    &:active {
        .activeTextPressed();
    }
}

/******** input ********/

.textInput(@color: @textColor) {
    font-weight: @fwRegular;
    font-size: @gui-font-size * 1.2;
    color: @color;
}

@extra-bold: 800;
@bold: 700;
@light: 100;

/******** FONT WEIGHTS ********/
// we could need some fallback
// when a font-family has fewer
// weight options

@fwBlack: 900;
@fwExtraBold: 800;
@fwBold: 700;
@fwSemiBold: 600;
@fwRegular: 400;
@fwThin: 300;
@fwLight: 200;
@fwExtraLight: @fwLight;

/********  TO BE SORTED ********/

@content-font: @gui-font; // ???
@content-font-size: @gui-font-size; // ???

/******** CONDENSED STYLE ********/

@cond-gui-font-size: 0.8rem;
