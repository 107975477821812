.switcher {
    .fa();
    .absolute(0px, @panel-rightW, initial, initial);
    z-index: 2;
    width: 20px;
    height: auto !important;
    background-color: @bodyBgColor;
    cursor: default;
    border: 1px solid @bodyBgColor;
    border-left-color: @borderLightColor;
    border-bottom-color: @borderLightColor;

    .switch-item {
        width: 100%;
        height: 1.5rem;

        .picto:before {
            .fa();
            .transition;
            font-size: 12px;
            width: 25px;
            height: 20px;
            padding-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.switch-base-map .picto:before {
            .far();
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.active .picto:before,
        .picto:hover:before {
            color: @activeColor;
        }
    }

    .switch-info .picto:before {
        content: @fa-var-info;
    }

    .switch-legend .picto:before {
        content: @fa-var-list-ul;
    }

    .switch-data .picto:before {
        content: @fa-var-table;
    }

    .switch-base-map .picto:before {
        content: @fa-var-map;
    }

    .switch-print .picto:before {
        content: @fa-var-print;
    }

    .switch-share .picto:before {
        content: @fa-var-share-alt;
    }

    .switch-measure .picto:before {
        content: @fa-var-arrows-alt-h;
    }

    .switch-locate .picto:before {
        content: @fa-var-crosshairs;
    }

    .switch-spatial-filter .picto:before {
        content: @fa-var-map-marker-alt;
    }
}

// .switch-background {
//     .absolute(initial, initial, 0, 0);
//     z-index: 2;
//     width: 75px;
//     height: 75px;
//     overflow: hidden;
//     background-color: @bodyBgColor;
//     border-top-right-radius: 1.5rem;
//     border-right: @borderLight;
//     border-top: @borderLight;

//     img {
//         object-fit: cover;
//         width: 100%;
//         height: 100%;
//         border-top-right-radius: 1.5rem;
//         border-right: @margin*0.5 solid @bodyBgColor;
//         border-top: @margin*0.5 solid @bodyBgColor;
//     }
// }


.switch-background {
    // .absolute(initial, 0, 0, initial);
    // z-index: 2;
    width: 2rem;
    height: 2rem;
    // overflow: hidden;
    background-color: @bodyBgColor;
    border-radius: 2rem;
    border: 2px solid @activeColor;


    // img {
    //     object-fit: cover;
    //     width: 100%;
    //     height: 100%;
    //     border-radius: 2rem;
    //     border: 2px solid @bodyBgColor;
    // }
}


///

@media @sdi-small {
    .switcher {
        right: 0px;
    }
}