.map-wrapper {
    &.print {
        .absolute(0, 0, 0, 0);
        width: 100px;
        height: 100px;
        z-index: 0;
    }



    .map-marker {
        .far();
        .fa-pulse();
        width: auto;
        height: auto;

        &:before {
            color: @activeColor;
            content: @fa-var-circle;
        }
    }
}