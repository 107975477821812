// header logos

html[lang='fr'] .brand-logo .brand-name {
    background-image: url('assets/imgs/BassinEFE_logo_en_ligne_RVB.jpg');
}

html[lang='nl'] .brand-logo .brand-name {
    background-image: url('assets/imgs/BassinEFE_logo_en_ligne_RVB.jpg');
}

html[lang='fr'] .brand-logo .brand-name {
    width: 335px;
}

html[lang='nl'] .brand-logo .brand-name {
    width: 295px;
}

.header {
    a {
        box-shadow: initial;
    }

    .links {
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        .link-item {
            color: @BE-blue!important;
            margin-right: .5rem;
            padding-bottom: .5rem;
            &:first-child {
                padding-top: .5rem;
            }
            a {
                cursor: pointer;
                &:hover {
                    color: @BE-black!important;
                }
            }
        }
    }
}

a {
    color: @blue !important;
    box-shadow: inset 0 -1px 0 0 @blue;
    text-decoration: none !important;
    border: 0px !important;
    &:hover {
        box-shadow: inset 0 -2px 0 0 @blue;
    }
    &:focus-visible {
        outline: 3px dotted @blue !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    //font-family: 'nunito', Helvetica, Arial, Verdana, sans-serif;
    font-family: 'helvetica-neue', Helvetica, Arial, Verdana, sans-serif;
    font-weight: @fwExtraBold;
    line-height: 1.05;
}

h1 {
    font-size: 2.353rem;
    color: @BE-blue-logo;
    line-height: 1.2;
}
h2 {
    font-size: 1.6471rem;
    color: @BE-green;
}
h3 {
    font-size: 1.412rem;
    color: @headingColor;
}
h4 {
    font-size: 1.1765rem;
    color: @headingColor;
}
h5,
h6 {
    font-size: 1rem;
    color: @headingColor;
}


.main {
    .legend-group.named:first-of-type {
        margin-top: 2.5rem;
        text-transform: uppercase;
    }
    .legend-group.named {
        text-transform: uppercase;
    }
}
