.legend-group {
    width: 100%;
}

.legend-decorated {
    position: relative;
    width: 100%;
    padding-right: (@margin / 2);
    border-right: @borderDark;

    &.visible {
        border-right-color: transparent;
    }

    .decorated>.btn-zoomOnLayer {
        .absolute(0, -0.6em, initial, initial);
        background-color: @bodyBgColor;
    }

    .with-opacity {
        .input-alpha-label {
            display: flex;
            align-items: center;
            font-size: 0.8rem;
            padding-left: calc(~'@{legend-itemW} + @{margin}');

            .label {
                flex-shrink: 0;
                margin-right: 1ch;
            }

            input {
                max-width: 8rem;
            }
        }

    }

}