.map-table__wrapper {
    table {
        width: 100%;

        thead {
            border-bottom: @borderLight;
            margin-bottom: @margin*0.5;

            tr {
                font-weight: @fwSemiBold;

                th {

                    button {
                        width: 100%;
                        text-align: left;
                        padding: 0.3rem 0.5em;

                        &:hover {
                            background-color: @bgActiveColor;
                        }

                        >span {
                            display: flex;
                            align-items: center;

                            &:first-letter {
                                text-transform: capitalize;
                            }

                            &.sorted {
                                font-weight: @fwSemiBold;
                            }

                            .fa {
                                margin-left: auto;
                                padding-left: @margin;
                            }
                        }

                    }
                }
            }
        }

        tbody {
            tr {
                &:hover {
                    background-color: @bgActiveColor;
                    cursor: default;
                }

                td {
                    padding: 0.3rem 0.5em;
                    max-width: 30ch;


                    &:first-child {
                        font-weight: @fwSemiBold;
                        min-width: 25ch;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &:nth-child(2) {
                        min-width: 25ch;
                    }
                }
            }
        }
    }
}