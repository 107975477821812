.transition {
    transition: all 0.1s;
}

.transitionSlow {
    transition: all 0.3s;
}

.absolute(@top, @right, @bottom, @left) {
    position: absolute;
    top: @top;
    right: @right;
    bottom: @bottom;
    left: @left;
}

.flex(@align: center, @justify: space-around, @wrap: wrap) {
    display: flex;
    align-items: @align;
    justify-content: @justify;
    flex-wrap: @wrap;
}

.shadow() {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.5);
    box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.5);
}

.no-shadow() {
    -webkit-box-shadow: initial;
    -moz-box-shadow: initial;
    box-shadow: initial;
}

@borderDark: 1px solid @borderDarkColor;
@borderLight: 1px solid @borderLightColor;

.border1px {
    border: @borderLight;
}

// TEXT & TYPO

.word-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.textLong() {
    line-height: 130%;
}

.link {
    .transition;
    cursor: pointer;

    &:hover {
        color: @linkColorHover;
    }
}

.labelStyle {
    display: block;
    font-weight: @fwBold;
    margin-bottom: @small-margin;
    text-decoration: underline;
}

.text-shadow(@color) {
    text-shadow: -1px -1px 0 @color, 1px -1px 0 @color, -1px 1px 0 @color,
        1px 1px 0 @color;
}



.legendItemPosition {
    width: @legend-itemW;
    margin-right: @margin;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8em;
}

.interactive {
    .transition;
    cursor: default;

    &:hover {
        color: @activeColor;
    }
}



//APP WIDGET BACKGROUND

.widgetBackground {
    background-color: @bgLightColor;
    padding: @small-margin;
}

.widgetActiveBackground {
    .transition;
    border: 1px solid @bgLightColor;
    background-color: @bgLightColor;
    padding: @small-margin;
    margin-bottom: @small-margin;

    &:hover {
        background-color: @bgLightColor + 15%;
        border: 1px solid @bgLightColor + 15%;
        cursor: default;
    }

    &.active {
        background-color: @bgLightColor;
        border: 1px solid @bgLightColor;
    }
}

// SCROLLBAR - will apply for browsers supporting scrollbar css specs

.scroll-mixin(@scroll-color: @inactiveColor) {
    scrollbar-width: thin;
    scrollbar-color: @scroll-color white;

    // will apply for webkit based browsers

    /* width */
    &::-webkit-scrollbar {
        width: (@margin / 2);
        height: (@margin / 2); //for horizontal scrollbar
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background-color: #fcfcfc;
        /* IE9, iOS 3.2+ */
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjAlIj48c3RvcCBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwLjQ5Ii8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAuNSIvPjxzdG9wIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwLjUxIi8+PHN0b3Agc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
        background-image: -webkit-gradient(
            linear,
            0% 0%,
            100% 0%,
            color-stop(0, rgb(255, 255, 255)),
            color-stop(0.49, rgb(255, 255, 255)),
            color-stop(0.5, rgb(0, 0, 0)),
            color-stop(0.51, rgb(255, 255, 255)),
            color-stop(1, rgb(255, 255, 255))
        );
        /* Android 2.3 */
        background-image: -webkit-repeating-linear-gradient(
            left,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 49%,
            rgb(0, 0, 0) 50%,
            rgb(255, 255, 255) 51%,
            rgb(255, 255, 255) 100%
        );
        /* IE10+ */
        background-image: repeating-linear-gradient(
            to right,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 49%,
            rgb(0, 0, 0) 50%,
            rgb(255, 255, 255) 51%,
            rgb(255, 255, 255) 100%
        );
        background-image: -ms-repeating-linear-gradient(
            left,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 49%,
            rgb(0, 0, 0) 50%,
            rgb(255, 255, 255) 51%,
            rgb(255, 255, 255) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffffff", endColorstr="#ffffffff", GradientType=1);
    }

    // /* Track */
    // &::-webkit-scrollbar-track {
    //     background: #000;
    //     background: #ffffff;
    //     /* Old browsers */
    //     background: -moz-linear-gradient(left, #ffffff 49%, #000000 50%, #ffffff 51%);
    //     /* FF3.6-15 */
    //     background: -webkit-linear-gradient(left, #ffffff 49%, #000000 50%, #ffffff 51%);
    //     /* Chrome10-25,Safari5.1-6 */
    //     background: linear-gradient(to right, #ffffff 49%, #000000 50%, #ffffff 51%);
    //     /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
    //     /* IE6-9 */
    // }
    // &::-webkit-scrollbar-track:horizontal {
    //     background: #000;
    //     background: #ffffff;
    //     /* Old browsers */
    //     background: -moz-linear-gradient(top, #ffffff 49%, #000000 50%, #ffffff 51%);
    //     /* FF3.6-15 */
    //     background: -webkit-linear-gradient(top, #ffffff 49%, #000000 50%, #ffffff 51%);
    //     /* Chrome10-25,Safari5.1-6 */
    //     background: linear-gradient(to bottom, #ffffff 49%, #000000 50%, #ffffff 51%);
    //     /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
    //     /* IE6-9 */
    // }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: @scroll-color;
        width: (@margin / 2);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: @scroll-color;
    }
}
