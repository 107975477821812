.timeserie-bar {
    font-family: monospace;
    // cursor: pointer;
    stroke: darkblue;
}

.timeserie-tick-label {
    fill: #666;
    font-size: 80%;
}

.timeserie-tick, .timeserie-border {
    stroke: #666;
    fill: none;
}

.timeserie-bar.active {
    fill: darkblue;
}

.timeserie-bar-label {
    display: none;
}

.timeserie-bar-label-line {
    stroke: darkblue;
    stroke-width: 1px;
    stroke-dasharray: 1,3;
    stroke-line-cap: 'round';
}
/*
.timeserie-bar:hover .timeserie-bar-label {
    display: initial;
    z-index: 1;
}*/

.timeserie-selection-border {
    stroke: darkblue;
    stroke-width: 1px;
    stroke-dasharray: 1,4;
    stroke-line-cap: 'round';
}

.timeserie-selection-adjust {
    stroke: darkblue;
    stroke-width: 1px;
    cursor: ew-resize;
    fill: white;
    display: block;
}

.timeserie-selection-fill {
    fill: lightblue;
    fill-opacity: .25;
}

.timeserie-bar-line {
    stroke: darkblue;   
    stroke-width: 1px;
    fill: transparent;
}

.timeserie-cursor {
    stroke: darkred;
    stroke-width: 1px;
}

.timeserie-data-point {
    fill: darkred;
}

.timeserie-grid { 
    stroke-width: 1px;
    stroke: rgb(150,150,150);
    stroke-dasharray: 1,3;
    stroke-line-cap: round;
}

.timeserie-norm {
    stroke-width: 1px;
    stroke: red;
}

.timeserie-wrapper {
    .chart-date-wrapper {
        .flex();
        justify-content: space-between;
        font-size: 80%;

        .date > *{
            width: 100%;
            display: block;
        }
    }
}

.timeserie-wrapper input {
    width: auto !important;
    margin-bottom: @margin;
    margin-top: @small-margin;
}

